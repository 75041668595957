import * as React from 'react';

import * as Sentry from '@sentry/browser';
import { ErrorEvent } from '@sentry/types';
import { translate } from '@showbie-socrative/socrative-utils/lib/translator/client';
import { QueryClient } from '@tanstack/react-query';
import * as moment from 'moment';
import { Provider } from 'react-redux';

import { Toaster } from '~shared/components/Toast';

import store from './src/store';
import {
  buildRateLimitedSentryEvent,
  getRateLimitedSentryEventInfo,
  isIgnoredErrorMessage,
  isIgnoredSentryError,
  isRateLimitedErrorMessage,
} from './src/util/sentryHandling';

const popup = require('../shared/components/popup/PopupController');
const user = require('../../shared/models/user');

Sentry.init({
  dsn: window.sentry_dsn,
  release: window.app_version,
  environment: window.sentry_env,
  // Reference: https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    'Network Error',
    'Request failed',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  beforeSend: function (event: ErrorEvent, hint: Sentry.EventHint) {
    const error = hint.originalException as any;

    // Explicitly ignored errors
    if (
      error &&
      error.message &&
      error.message.match(/reading 'CodeMirror'/i)
    ) {
      return null;
    }
    try {
      const rateLimitedEventInfo = getRateLimitedSentryEventInfo(event);
      if (rateLimitedEventInfo) {
        return buildRateLimitedSentryEvent(event, rateLimitedEventInfo);
      } else if (isIgnoredSentryError(event)) {
        return null;
      }
    } catch (e) {
      // @todo Catch the error.
    }
    return event;
  },
});

interface Props {
  user: any;
}
export class AppView extends React.Component<Props> {
  QueryClient: QueryClient = null;
  constructor(props) {
    super(props);
    const user = props.user;

    Sentry.configureScope((scope) => {
      try {
        const id = user.isTeacher()
          ? user.getId()
          : user.getRoomName().toLowerCase();
        scope.setUser({ id: id.toString() });
        scope.setTag('role', user.isTeacher() ? 'teacher' : 'student');
      } catch (err) {
        console.error(err);
      }
    });

    this.QueryClient = new QueryClient();
  }

  componentDidMount() {
    window.onerror = this.handleAppError;
    window.addEventListener('unhandledrejection', function (event) {
      console.error(event);
      event.stopPropagation();
    });
  }
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  handleAppError(message, source, lineno, colno, appError) {
    let sentryEventId = null;
    let displayErrorToUser = true;

    // Log error in sentry
    try {
      if (appError) {
        sentryEventId = Sentry.captureException(appError);
        if (
          appError.stack &&
          appError.stack.includes('mathlive.bundle.min.js')
        ) {
          displayErrorToUser = false;
        }
      } else if (message) {
        const isMessageIgnored = isIgnoredErrorMessage(message);
        if (!isMessageIgnored) {
          sentryEventId = Sentry.captureException(message);
        }

        if (isMessageIgnored || isRateLimitedErrorMessage(message)) {
          displayErrorToUser = false;
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (!displayErrorToUser) {
      return true;
    }

    try {
      const whitelistErrorUrlRegex = RegExp('(socrative|showbie)+', 'g');
      // Check if error comes from a whitelisted source
      if (source && source.length && whitelistErrorUrlRegex.test(source)) {
        // Display dismissible popup to user
        popup.closePopup();

        const buildErrorDetails = ({
          message,
          sentryEventId,
          isStudent = false,
        }: {
          message: string;
          sentryEventId: string;
          isStudent?: boolean;
        }) => {
          sentryEventId = sentryEventId || 'Unknown';
          let errorMessage = 'Could not get error details';
          try {
            errorMessage = message.toString();
          } catch (err) {
            console.error(err);
          }

          return (
            <div className={'popup-error-details'}>
              <strong>
                {translate('Error Details')}
                <br />
              </strong>
              <span>
                {isStudent
                  ? translate('Please provide this info to your teacher')
                  : translate('Please provide this info to our support team')}
              </span>
              <p>[Event ID]: {sentryEventId}</p>
              <p>[App Version]: {window.app_version}</p>
              <p>[Timestamp]: {moment.utc().toString()}</p>
              <p>[Error]: {errorMessage}</p>
            </div>
          );
        };

        if (user.isTeacher()) {
          popup.render({
            title: translate('Hmm, something unexpected happened.'),
            message: [
              translate(
                `Please refresh and try again. If problems persist, don’t hesitate to contact us for help!`
              ),
              buildErrorDetails({
                message: message,
                sentryEventId: sentryEventId,
              }),
            ],
            buttonText: translate('Refresh'),
            buttonClicked: () => {
              window.location.reload();
            },
            cancelText: translate('Logout'),
            cancelClicked: (closeXClicked: any) => {
              if (closeXClicked) {
                return;
              }

              user.logout(() => {
                window.location.href = '/login/teacher/';
              });
            },
            isModal: true,
          });
        } else {
          popup.render({
            title: translate('Hmm, something unexpected happened.'),
            message: [
              translate(
                `Your answer may not have been saved. Refreshing may help, but you may also lose your answer.`
              ),
              buildErrorDetails({
                message: appError,
                sentryEventId: sentryEventId,
                isStudent: true,
              }),
            ],
            buttonText: translate('Try Again'),
            cancelText: translate('Refresh'),
            cancelClicked: (closeXClicked: any) => {
              if (closeXClicked) {
                return;
              }

              window.location.reload();
            },
            isModal: true,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }

    return true;
  }

  render() {
    return (
      <Provider store={store}>
        <Toaster />

        <div
          id="main-content"
          style={{
            minHeight: window.innerWidth < 768 ? 'calc(100vh - 67px)' : '0',
          }}
        />
      </Provider>
    );
  }
}
